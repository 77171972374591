<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <AboutIntro />
      <AboutStory />
      <CtaAlternative />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import AboutIntro from './../partials/AboutIntro.vue'
import AboutStory from './../partials/AboutStory.vue'

import CtaAlternative from './../partials/Cta.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'ProcTrimbleConnect',
  title: 'Miért válassza a Trimble Connectet?',
  components: {
    Header,
    AboutIntro,
    AboutStory,

    CtaAlternative,
    Footer,
  },
}
</script>
