<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">
 
        <!-- Section header -->
        <div class="max-w-6xl mx-auto text-left pb-12 md:pb-16">
          <h1 class="h1 mb-4">Miért válassza a Trimble Connectet?</h1>
          <p class="text-xl text-gray-600">
            A legfontosabb okok, amiért érdemes a Trimble Connectet választania közös adatkörnyezet (CDE) megoldásként. A mindig naprakész, átlátható és könnyen hozzáférhető információk segítségével teljes áttekintést kaphat a projektek aktuális állapotáról. 
            A projekthez tartozó összes dokumentum és modell nyilvántartása, rendezése és tárolása egy helyen történik. A felhasználók szerepköreinek és jogosultságainak megadásával nyomon követhető a tevékenységük. A feladatok célirányos körbeküldésével a csökkenthető a nagymératű állományokkal terhelt e-mail forgalom.

          </p>
        </div>

        <figure class="flex justify-center items-start" data-aos="zoom-y-out">
          <img class="rounded shadow-xl" :src="require('@/images/tc/procTC.webp')" width="100%" height="432" alt="About us" />
        </figure>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutIntro',
}
</script>