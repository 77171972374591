<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="">

        <div class="max-w-6xl mx-auto">
          <div class="text-left">
            <p class="text-m text-gray-400">1. OK</p>
            <p class="text-3xl mb-3 font-bold">Modern felhőalapú tárolás</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
            A Trimble Connect az Amazon Web Services modern felhőmegoldásait használja. Ez biztosítja a magas szintű adatvédelmet, valamint az összes információ gyors átvitelét. A Trimble Connect eszközzel való kommunikáció HTTPS-en keresztül történik, biztosítva, hogy a kommunikáció TLS (SSL) titkosítással legyen történjen. A Trimble Connect alkalmazáson belül generált adatokat biztonságos adatbázisokban tárolják, amelyekről naponta biztonsági mentést készítenek. Ezzel a modern megoldással az adatok és információk nagyfokú biztonságban vannak, ugyanakkor bárhol és bármikor hozzáférhetők. A felhőben rendelkezésre álló tárhelynek nincs korlátja.
          </p>

          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">2. OK</p>
            <p class="text-3xl mb-3 font-bold">Nyitottság minden fájlformátumra (2D és 3D, beleértve a natív formátumot is)</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
            A Trimble Connect integrálja a piac legfejlettebb és leggyorsabb 2D és 3D BIM megjelenítőjét, amely számos fájlformátumot képes megnyitni. Nem számít, hogy milyen programot használ az adatok és modellek létrehozásához. 
            A fejlett és modern 3D megjelenítőben különböző forrásból – OPEN BIM elveket követve pl.: IFC, Tekla, Revit, DWG, DXF, DGN, SKP, IGS, STEP, LandXML és TRimBim - származó modelleket összekapcsolhatjuk, nézetekbe rendezhetjük és együtt tovább használhatjuk kiegésztve E57, LAS, LAZ, Potree, YXZ pontfelhő információ tartalommal. Közvetlenül a Tekla Structures, Autodesk Revit vagy SketchUp program alól is feltölthetőek és frissíthetőek a modellek. Még az is lehetséges, hogy különböző fájl formátumú kombinációkat nyisson meg egyszerre és mentsen el egy új modellként.
            Természetesen fejlett ütközésellenőrzés is rendelkezésre áll. Mindez anélkül, hogy bármilyen programot külön telepítenie kellene.

          </p>

          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">3. OK</p>
            <p class="text-3xl mb-3 font-bold">A teljes projekttörténet dokumentálása</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
              A Trimble Connect BIM-megoldás erőssége a projektben végzett összes tevékenység előzménye. A projekt kezdetétől kezdve minden tevékenység elmentésre kerül. Így, ha valaki hozzáad, letölt vagy megoszt egy fájlt, hozzátesz egy megjegyzést, létrehoz egy új nézetet, feltölti egy dokumentum módosítását, vagy esetleg feladatot ad egy másik személynek, minden lépés elmentésre kerül a tevékenység oldalon. A tevékenységeket listázni, rendezni és szűrni is lehet, nincsen később vita a kollégákkal arról, hogy valaki nem értesült az eseményekről. A Trimble Connect segítségével bármikor teljes áttekintést kaphat az összes projekttevékenységről, amikor csak szüksége van rá.
          </p>

          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">4. OK</p>
            <p class="text-3xl mb-3 font-bold">Hatékony csapatirányítás és hozzáférési jogosultságok</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
             A Trimble Connect lehetővé teszi, hogy szereplőket adjon hozzá egy projekthez többféle módon. Támogatja a különböző szakmai csoportok létrehozását és a szükséges jogosultságok hozzárendelését. A jogosultságok beállítása programrészenként kifinomultan szabályozható. Ezáltal adatai biztonságban és a visszaélésekkel szemben védve maradnak.
          </p>

          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">5. OK</p>
            <p class="text-3xl mb-3 font-bold">Online és offline munka</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
             Mindig maradj a kapcsolatban a projektinformációkkal, még akkor is, ha offline vagy. Az építkezéseken nem mindig a legjobb az internetkapcsolat, és néha szükség van arra, hogy offline is hozzáférjünk az információkhoz és a fájlokhoz. A Trimble Connect technológia segít az összes fontos fájl és információ helyi másolatának megőrzésében és az adatok szinkronizálásában. Így amit fontosnak tart, mindig kéznél van. online és offline.
          </p>
          
          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">6. OK</p>
            <p class="text-3xl mb-3 font-bold">Modell információk kinyerése és táblázatba rendezése</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
            A Trimble Connect segítségével a 3D modell elemeiből történő hatékony és gyors jelentés készítés olyan egyszerű, mint még soha. Egy pillanat alatt meg lehet tudni a beton szerkezetek köbtartalmát, az acélelemek mennyiségét vagy a zsaluelemek pontos számát. A néhány másodperc alatt kinyert adatokat, a meghatározott jelentést exportálhatja egy excel-fájlba, amellyel tovább dolgozhat vagy átadhat információkat más programok számára.
          </p>
                    
          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">7. OK</p>
            <p class="text-3xl mb-3 font-bold">Egyéni tulajdonságkészlet - saját attribútumok és metaadatok hozzáadása</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
             Meghatározhatja saját vállalatának metaadatait az egyes fájlokhoz és alkönyvtárakhoz, vagy saját tulajdonság készletet hozhat létre, amivel kiterjesztheti a 3D modell egyes elemeinek attribútum adatait. Ezután használja őket jelentések készítéséhez vagy a munkafolyamatok beállításához.
          </p>
                              
          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">8. OK</p>
            <p class="text-3xl mb-3 font-bold">Hatékony feladatkiosztás</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
             Kezelje együtt az egész csapatának a feladatait. A projektben való közös munka megköveteli a feladatok megosztását és a felelősségek delegálását a csapattagok között. A Trimble Connect lehetővé teszi a csapatban dolgozó munkatársak feladatainak kiosztását és kezelését, és egy áttekinthető listát is megjelenít az adott határidőre elvégzendő feladatokról.
          </p>
                                        
          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">9. OK</p>
            <p class="text-3xl mb-3 font-bold">Magyar nyelvű ügyfélszolgálat és támogatás</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
             A Construsoft Kft. csapata gyors és szakértő magyar nyelvű ügyfélszolgálatot biztosít akár telefonos vagy e-mail-es kérdések megválaszolására a tőlünk vásárolt, vagy Tekla Structure programhoz kapcsolt licencekhez. Emellett személyre szabott képzéseket is biztosítunk, beleértve a Trimble Connect BIM gyakorlati megvalósítására vonatkozó tanácsadást.
          </p>
                                                  
          <hr><br>

          <div class="text-left">
            <p class="text-m text-gray-400">10. OK</p>
            <p class="text-3xl mb-3 font-bold">Kedvező, alacsony ár</p>
          </div>
          <p class="text-lg text-justify text-gray-600 mb-8">
             A Trimble Connect az elérhető konkurens megoldásokhoz képest még a kisebb vállalatok és magánszemélyek számára is megfizethető. A standard előfizetések mellett lehetőség van cég ill projektalapú egyedi árképzésre is, ahol tiszteletben tartjuk ügyfeleink igényeit. A Trimble Connect így magas minőséget és korlátlan lehetőségeket kínál megfizethető áron.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutStory',
}
</script>